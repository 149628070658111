body,html,#root {
    height:100%;
}
.container {
    min-height:100%;
    padding-bottom:60px;
}
@media (min-width: 768px) {
    .container {
        background-image: url("style/img1.png");
        background-position-x: right;
        background-position-y: top;
        background-repeat: no-repeat;
    }
}


.footer {
    height:50px;
    margin-top:-50px;
}

.name-row {
    background-image: url("style/img1.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}