@font-face {
  font-family: GreatVibes-Regular;
  src: url(GreatVibes-Regular.ttf);
}

$font-size-base: 2rem;

$body-bg: #ffffff;
$body-color: #62245f;
$font-family-base: "GreatVibes-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
$primary: #62245f;
@import "../../node_modules/bootstrap/scss/bootstrap.scss";